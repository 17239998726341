var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "side_nav"
  }, [_c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path.includes('/admin/users') ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/users/all"
    }
  }, [_vm.$route.path.includes('/admin/users') ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Profile_active.svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Profile.svg"),
      "alt": "img"
    }
  }), _vm._v(" Users ")])], 1), _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path.includes('/admin/sender_ids/pending') ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/sender_ids/pending"
    }
  }, [_vm.$route.path.includes('/admin/sender_ids/pending') ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/lock_active.svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Lock.svg"),
      "alt": "img"
    }
  }), _vm._v(" Sender IDs ")])], 1), _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path.includes('/admin/wallet_history_fr') ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/wallet_history_fr"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_vm.$route.path.includes('/admin/wallet_history_fr') ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Wallet__active.svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Wallet.svg"),
      "alt": "img"
    }
  }), _vm._v(" Wallet History ")])])], 1), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path == '/admin/sender_ids/PendingReseller' ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/sender_ids/PendingReseller"
    }
  }, [_vm.$route.path.includes('/admin/sender_ids/PendingReseller') ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/lock_active.svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Lock.svg"),
      "alt": "img"
    }
  }), _vm._v(" Reseller Sender IDs ")])], 1) : _vm._e(), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path == '/admin/mass_messages' ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/mass_messages"
    }
  }, [_vm.$route.path == '/admin/mass_messages' ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/message_active.svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/default.svg"),
      "alt": "img"
    }
  }), _vm._v(" Mass Messages ")])], 1) : _vm._e(), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path.includes('finance') ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/finance"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_vm.$route.path.includes('/admin/finance') ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Wallet__active.svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Wallet.svg"),
      "alt": "img"
    }
  }), _vm._v(" Finance ")])])], 1) : _vm._e(), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path == '/admin/notifications' ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/notifications"
    }
  }, [_vm.$route.path == '/admin/notifications' ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Notification.svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Notification (1).svg"),
      "alt": "img"
    }
  }), _vm._v(" Notifications ")])], 1) : _vm._e(), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path.includes('resellers') ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/resellers/pending"
    }
  }, [_vm.$route.path.includes('resellers') ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Ticket Star (1).svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Ticket Star.svg"),
      "alt": "img"
    }
  }), _vm._v(" Resellers ")])], 1) : _vm._e(), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path.includes('/admin/reporting') ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/reporting/industries"
    }
  }, [_vm.$route.path.includes('/admin/reporting') ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/reporting_active.svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/reporting.svg"),
      "alt": "img"
    }
  }), _vm._v(" Reporting ")])], 1) : _vm._e(), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path.includes('/admin/promo_codes') ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/promo_codes"
    }
  }, [_vm.$route.path.includes('/promo_codes') ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Ticket Star (1).svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Ticket Star.svg"),
      "alt": "img"
    }
  }), _vm._v(" Promos & Referrals ")])], 1) : _vm._e(), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path == '/admin/industries' ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/industries"
    }
  }, [_vm.$route.path == '/admin/industries' ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Ticket Star (1).svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Ticket Star.svg"),
      "alt": "img"
    }
  }), _vm._v(" Industries ")])], 1) : _vm._e(), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path == '/admin/international_rates' ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/international_rates"
    }
  }, [_vm.$route.path == '/admin/international_rates' ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Ticket Star (1).svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Ticket Star.svg"),
      "alt": "img"
    }
  }), _vm._v(" International Rates ")])], 1) : _vm._e(), !_vm.disallowedUsers ? _c('li', {
    staticClass: "side_nav_item",
    class: [_vm.$route.path.includes('/admin/settings') ? 'active' : '']
  }, [_c('router-link', {
    staticClass: "side_nav_link",
    attrs: {
      "to": "/admin/settings"
    }
  }, [_vm.$route.path.includes('admin/settings') ? _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/SettingActive.svg"),
      "alt": "img"
    }
  }) : _c('img', {
    staticClass: "mr-3 ml-2",
    attrs: {
      "height": "20px",
      "width": "20px",
      "src": require("@/assets/Setting.svg"),
      "alt": "img"
    }
  }), _vm._v(" Settings ")])], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };