export default {
  name: "mobile_menu",
  data() {
    return {};
  },
  computed: {
    disallowedUsers() {
      return this.$store.getters["auth/disallowedUsers"];
    }
  }
};